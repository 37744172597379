<template>
  <List
    :config="blockData.config"
    :initial-data="blockData.initialData"
    :initial-filters="blockData.initialFilters"
    state-in-url
  >
    <template #entries="{ entries, filters }">
      <TileView
        :tiles="entries"
        :grid-type="ETileGridType.SINGLE"
        :query-args="getQueryArgsForTiles(filters)"
      />
    </template>
    <template #no-entries>
      <NoEntries :type-handle="blockData.typeHandle" />
    </template>
    <template #loader>
      <LoaderTileRow></LoaderTileRow>
      <LoaderTileRow class="mt-md"></LoaderTileRow>
    </template>
    <template #filters="{ filters, filterOptions, changeFilter }">
      <div class="pt-0 bg-white md:pt-md">
        <div class="p-sm md:bg-vdv-grey md:p-0">
          <TagFilter
            :filter-key="FilterType.TAGS"
            :filters="filters"
            :filter-options="filterOptions"
            @change-filter="changeFilter"
          />
        </div>
      </div>
    </template>
  </List>
</template>

<script setup lang="ts">
import { ETileGridType } from '@/@types/tile-grid-type';
import { scrollToElement } from '@/helpers/scroll';
import LoaderTileRow from '@/components/loader/tile-row.vue';
import NoEntries from '../../components/noEntries/vdv/no-entries.vue';
import TileView from '@/components/components/views/tileView/tile-view.vue';
import { SSR_safe_mq_breakpointIsMobile } from '@/injectionSymbols';
import type { PostListData } from '../blockType';
import List from '@/components/components/advancedList/list.vue';
import TagFilter from '@/components/components/advancedList/components/filters/tagFilter/tagFilter.vue';
import { FilterType } from '../../components/filter/utils';

const props = defineProps({
  blockData: {
    type: Object as PropType<PostListData>,
    required: true,
    default: {} as PostListData,
  },
});

const isMobile = inject(SSR_safe_mq_breakpointIsMobile);
const router = useRouter();

// scroll to list if filter is set in url query
onMounted(async () => {
  if (
    router.currentRoute?.value.query &&
    Object.keys(router.currentRoute.value.query).length
  ) {
    const element = document.getElementById(props.blockData.id);
    scrollToElement(element, isMobile ? 0 : 120);
  }
});

function getQueryArgsForTiles(filter: Record<string, any>) {
  return Object.entries(filter).reduce(
    (acc, [k, v]) => {
      acc[k] = encodeURI(JSON.stringify(v));
      return acc;
    },
    {} as Record<string, string>,
  );
}
</script>
